import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-danger" }
const _hoisted_2 = { class: "text-danger" }
const _hoisted_3 = { class: "text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_DivCol = _resolveComponent("DivCol")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_ButtonCustom = _resolveComponent("ButtonCustom")!
  const _component_DivRow = _resolveComponent("DivRow")!
  const _component_AddVatTuTableItems = _resolveComponent("AddVatTuTableItems")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isOpen) = $event)),
    width: "70%",
    title: _ctx.titleDisplay,
    onClose: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DivRow, { additionalClass: "align-content-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_DivCol, {
            cols: 3,
            additionalClass: "flex align-content-center align-items-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_autocomplete, {
                modelValue: _ctx.tenDanhMucVatTu,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tenDanhMucVatTu) = $event)),
                ref: "danhMucVatTuInput",
                class: "w-100",
                clearable: "",
                placeholder: "Tìm kiếm DMVT",
                "value-key": "ten_danh_muc",
                "trigger-on-focus": false,
                "fetch-suggestions": _ctx.queryDanhMucVatTu,
                "highlight-first-item": true,
                onSelect: _ctx.onSelectDanhMucVatTu,
                onFocus: _cache[1] || (_cache[1] = ($event: any) => ($event.target.select()))
              }, {
                default: _withCtx(({ item }) => [
                  _createElementVNode("span", null, [
                    _createElementVNode("strong", null, _toDisplayString(item.ma_danh_muc) + " - ", 1)
                  ]),
                  _createElementVNode("span", null, _toDisplayString(item.ten_danh_muc), 1)
                ]),
                _: 1
              }, 8, ["modelValue", "fetch-suggestions", "onSelect"]),
              _withDirectives(_createElementVNode("span", _hoisted_1, "Không có dữ liệu", 512), [
                [_vShow, !_ctx.isDanhMucItemsAvailable]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_DivCol, {
            cols: 3,
            additionalClass: "flex align-content-center align-items-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_autocomplete, {
                modelValue: _ctx.tenVatTu,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tenVatTu) = $event)),
                ref: "vatTuInput",
                class: _normalizeClass(["w-100", { 'vat-tu-input-class-no-result': !_ctx.isVatTuItemsAvailable }]),
                clearable: "",
                placeholder: "Tìm kiếm vật tư",
                "value-key": "ten_vat_tu",
                "trigger-on-focus": false,
                "fetch-suggestions": _ctx.queryVatTu,
                "highlight-first-item": true,
                onSelect: _ctx.onSelectVatTu,
                onFocus: _cache[3] || (_cache[3] = ($event: any) => ($event.target.select())),
                onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isSelected = false))
              }, {
                default: _withCtx(({ item }) => [
                  _createElementVNode("span", null, [
                    _createElementVNode("strong", null, _toDisplayString(item.ma_vat_tu) + " - ", 1)
                  ]),
                  _createElementVNode("span", null, _toDisplayString(item.ten_vat_tu), 1)
                ]),
                _: 1
              }, 8, ["modelValue", "class", "fetch-suggestions", "onSelect"]),
              _withDirectives(_createElementVNode("span", _hoisted_2, "Không có dữ liệu", 512), [
                [_vShow, !_ctx.isVatTuItemsAvailable]
              ]),
              _withDirectives(_createElementVNode("span", _hoisted_3, "Vật tư đã chọn", 512), [
                [_vShow, _ctx.isSelected]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_DivCol, {
            additionalClass: "flex align-content-center align-items-center",
            cols: 2
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input_number, {
                modelValue: _ctx.so_luong,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.so_luong) = $event)),
                ref: "soLuongInput",
                type: "number",
                min: 0.01,
                max: _ctx.useFor === _ctx.AddVatTuItemsFor.PHIEU_NHAP_KHO_KHAC ? +_ctx.soLuongLimit : Number.POSITIVE_INFINITY,
                placeholder: "Số lượng",
                value: 1,
                step: 0.01,
                disabled: _ctx.vatTuId === 0,
                size: "small",
                onFocus: _cache[6] || (_cache[6] = ($event: any) => ($event.target.select()))
              }, null, 8, ["modelValue", "max", "disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_DivCol, { additionalClass: "flex align-content-center align-items-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_ButtonCustom, {
                "button-type": _ctx.ButtonsType.ADD,
                "is-disabled": _ctx.isAddButtonDisable,
                onClick: _ctx.onAddVatTu
              }, null, 8, ["button-type", "is-disabled", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_AddVatTuTableItems, {
        "is-loading": _ctx.isLoading,
        items: _ctx.selectingVatTuItems,
        "show-price-and-total": true,
        onOnDelete: _ctx.onRemoveItem
      }, null, 8, ["is-loading", "items", "onOnDelete"]),
      _createVNode(_component_DivRow, { "additional-class": "mt-2" }, {
        default: _withCtx(() => [
          _createVNode(_component_DivCol, { cols: 11 }),
          _createVNode(_component_DivCol, { additionalClass: "text-end" }, {
            default: _withCtx(() => [
              (_ctx.selectingVatTuItems?.length > 0)
                ? (_openBlock(), _createBlock(_component_ButtonCustom, {
                    key: 0,
                    "button-type": _ctx.ButtonsType.SAVE,
                    "color-type": _ctx.ButtonTypeColors.SUCCESS,
                    onClick: _ctx.submit
                  }, null, 8, ["button-type", "color-type", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "title", "onClose"]))
}